import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  apiDomain			=	globalConstant.DOMAIN;
  HomeSectionApi	=	globalConstant.HOME_SECTION_API;
  getHomePageExpApi  = globalConstant.GET_HOME_PAGE_EXPERIENCE_API
  siteRequestFrom					=	globalConstant.SITE_REQUEST_FROM;
  deviceType  = globalConstant.DEVICE_TYPE

  constructor(public router: Router,private http: HttpClient) {    
    if(window.orientation > -1){
      this.deviceType = "mobile";
    }else{
      this.deviceType = "desktop";
    }
  } 

  //get page sections.
  get_sections(){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        //'Content-Type': 'application/x-www-form-urlencoded',
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
    return this.http.get<any>(this.apiDomain+this.HomeSectionApi,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  
  //get page sections.
  getHomePageExp(headers){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
        'device' :  this.deviceType
      })
    };
    return this.http.post<any>(this.apiDomain+this.getHomePageExpApi,{},httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }   
}




