import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class ThankyouService {
  apiDomain					=	globalConstant.DOMAIN;
  thankYouApi				=	globalConstant.THANK_YOU_API;
  siteRequestFrom			=	globalConstant.SITE_REQUEST_FROM;
  partialOrderShareEmailApi	=	globalConstant.PARTIAL_ORDER_SHARE_EMAIL_API;
  viewAllVoucherApi			=	globalConstant.VIEW_ALL_VOUCHERS_API;

  
  constructor(public router: Router,private http: HttpClient) {  

  }

  thankYou(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
      })
    }; 
    return this.http.post<any>(this.apiDomain+this.thankYouApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  viewAllVoucher(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
      })
    }; 
    return this.http.post<any>(this.apiDomain+this.viewAllVoucherApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  partialyPurchaseShareEmail(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom,
      })
    }; 
    return this.http.post<any>(this.apiDomain+this.partialOrderShareEmailApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  
  
}




