import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class CharityService {
  apiDomain	   	    			=	globalConstant.DOMAIN;
  siteRequestFrom				=	globalConstant.SITE_REQUEST_FROM;
  getCharitiesApi				=	globalConstant.GET_CHARITIES;
  getCharityApi					=	globalConstant.GET_CHARITY;
  charityCoupleApi				=	globalConstant.CHARITY_COUPLE_API;
  sendCharityThankyouEmailApi	=	globalConstant.SEND_CHAIRTY_THANK_YOU_EMAIL;
  charityCoupleReportApi		=	globalConstant.CHARITY_COUPLE_REPORT_API;
  removeCharityVideoApi			=	globalConstant.REMOVE_CHARITY_VIDEO_API;
  updateCharityStatusApi		=	globalConstant.UPDATE_CHARITY_STATUS;
  
  constructor(public router: Router,private http: HttpClient) {  

  } 

 
 get_charities(formData, authToken=''){
	 var headerData 	=	{
								//'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
								'Content-Type': 'application/json',
								'requestFrom':this.siteRequestFrom
							};
	  
	  if(authToken != ''){
		 headerData['Authorization']	=	'Bearer '+authToken; 
	  }
	
	const httpOptions = {
      headers: new HttpHeaders(headerData)
    };
    return this.http.post<any>(this.apiDomain+this.getCharitiesApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
	
  }
  get_charity(formData){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom
      })
    };
    return this.http.post<any>(this.apiDomain+this.getCharityApi,formData,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
	
  }
   
  charity_couple(headers,data){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'requestFrom':this.siteRequestFrom,
          'Authorization':'Bearer '+headers
        })
      };
      return this.http.post<any>(this.apiDomain+this.charityCoupleApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {

        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
    
    }
	
	charity_couple_report(data,headers){
	const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'requestFrom':this.siteRequestFrom,
          'Authorization':'Bearer '+headers
        })
      };
      return this.http.post<any>(this.apiDomain+this.charityCoupleReportApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {

        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
    
    }
    
    
    
  sendCharityThankyouEmail(headers,data){
    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'requestFrom':this.siteRequestFrom,
          'Authorization':'Bearer '+headers
        })
      };
      return this.http.post<any>(this.apiDomain+this.sendCharityThankyouEmailApi,data,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {

        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
    
    }
	
	removeVideo(data, headers){
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  'requestFrom':this.siteRequestFrom,
			  'Authorization':'Bearer '+headers
			})
		};
		//console.log(data);
		//console.log(httpOptions);
		return this.http.post<any>(this.apiDomain+this.removeCharityVideoApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {

			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
    
    }
	update_charity_status(data, headers){
		const httpOptions = {
			headers: new HttpHeaders({
			  //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
			  'Content-Type': 'application/json',
			  'requestFrom':this.siteRequestFrom,
			  'Authorization':'Bearer '+headers
			})
		};
		return this.http.post<any>(this.apiDomain+this.updateCharityStatusApi,data,httpOptions).pipe(map(res=>{
			return res;
		}),
		catchError(err => {

			if(err.status == 401)
			{
			  localStorage.removeItem('authUser')
			  localStorage.removeItem('login_token')
			  localStorage.removeItem('profile_img_url')
			  window.location.href = '/login'
			}
			return err.error;
		}));
    
    }
	
	
	
}




