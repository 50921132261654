import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class BlogCategoryService {
  apiDomain		=	globalConstant.DOMAIN;
  categoryApi	=	globalConstant.BLOG_CATEGORY_API;
  articleApi	=	globalConstant.BLOG_ARTICLE_API;
  detailApi	  =	globalConstant.BLOG_DETAILS_API;
  catDetail	  =	globalConstant.CATEGORIES_DETAIL;
  
  siteRequestFrom					=	globalConstant.SITE_REQUEST_FROM;

  constructor(public router: Router,private http: HttpClient) {  

  } 

  get_blogcategory(){

	const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        //'Content-Type': 'application/x-www-form-urlencoded',
        'requestFrom':this.siteRequestFrom
      })
    };
    return this.http.get<any>(this.apiDomain+this.categoryApi,httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  
  get_blogarticle(){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'requestFrom':this.siteRequestFrom
        })
      };
      return this.http.get<any>(this.apiDomain+this.articleApi,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        return err.error;
      }));
    }

    get_blogdetail(formData){

      const httpOptions = {
          headers: new HttpHeaders({
            //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
            'Content-Type': 'application/json',
            'requestFrom':this.siteRequestFrom
          })
        };
        return this.http.post<any>(this.apiDomain+this.detailApi,formData,httpOptions).pipe(map(res=>{
          return res;
        }),
        catchError(err => {
          return err.error;
        }));
      }
  
      get_categoriesdetail(formData){

        const httpOptions = {
            headers: new HttpHeaders({
              //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
              'Content-Type': 'application/json',
              'requestFrom':this.siteRequestFrom
            })
          };
          return this.http.post<any>(this.apiDomain+this.catDetail,formData,httpOptions).pipe(map(res=>{
            return res;
          }),
          catchError(err => {
            return err.error;
          }));
        }
  
}




