import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  apiDomain			=	globalConstant.DOMAIN;
  faqCategoryApi	=	globalConstant.FAQ_CATEGORY_API;
  faqPostApi		=	globalConstant.FAQ_POST_API;
  faqDetailApi		=	globalConstant.FAQ_DETAIL_API;
  siteRequestFrom					=	globalConstant.SITE_REQUEST_FROM;
  constructor(public router: Router,private http: HttpClient) {    

  } 

  //Faq Categories DETAILS
  faq_category(){

   const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Api-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'requestFrom':this.siteRequestFrom
      })
    };  
    
    return this.http.post<any>(this.apiDomain+this.faqCategoryApi,"",httpOptions).pipe(map(res=>{
      return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }
  
  //Faq Details by category
  faqs_by_category(slug, search=""){

   const httpOptions = {
      headers: new HttpHeaders({
        //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
        'Content-Type': 'application/json',
        'requestFrom':this.siteRequestFrom
      })
    };  
    // const formData = new FormData();
	  // formData.append('slug',slug);
	
	return this.http.post<any>(this.apiDomain+this.faqPostApi,{'slug':slug, 'search':search},httpOptions).pipe(map(res=>{
		return res;
    }),
    catchError(err => {
      return err.error;
    }));
  }


    get_faqdetail(slug){

      const httpOptions = {
         headers: new HttpHeaders({
           //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
           'Content-Type': 'application/json',
           'requestFrom':this.siteRequestFrom
         })
       };  
      //  const formData = new FormData();
      //  formData.append('slug',slug);
     
     return this.http.post<any>(this.apiDomain+this.faqDetailApi,{'slug':slug},httpOptions).pipe(map(res=>{
       return res;
       }),
       catchError(err => {
         return err.error;
       }));
     }
  
	/* faqByCategory(slug){
		this.router.navigate(['/faq-category/',""], { queryParams: {slug:slug} });
	} */
}




