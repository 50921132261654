import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError,map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import * as globalConstant from './../global_constant';

@Injectable({
  providedIn: 'root'
})
export class OurFriendsService {
  apiDomain	   	        =	globalConstant.DOMAIN;
  referFriendAPI	    =	globalConstant.REFER_FRIEND_API;
  getVeboFriendsAPI	    =	globalConstant.GET_VEBO_FRIENDS_API;
  getVeboFriendsByOtherAPI	    =	globalConstant.GET_VEBO_FRIENDS_BY_OTHER_API;
  removeFriendAPI	    =	globalConstant.REMOVE_FRIEND_API;
  sendFriendRequestAPI	    =	globalConstant.SEND_FRIEND_REQUEST_API;
  confirmFriendRequestAPI	    =	globalConstant.CONFIRM_FRIEND_REQUEST_API;
  siteRequestFrom					=	globalConstant.SITE_REQUEST_FROM;
  getCouplesApi					=	globalConstant.GET_COUPLES_API;
  getNewsFeedApi					=	globalConstant.GET_NEWS_FEED_API;
  
  
  constructor(public router: Router,private http: HttpClient) {  
  } 
    
  refer_friends(formData,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.referFriendAPI,formData,httpOptions).pipe(map(res=>{
        return res;
    }),
    catchError(err => {

      if(err.status == 401) 
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }

      return err.error;
    }));
  }
    
  
  get_vebo_friends(formData,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':headers == "" ? "":'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.getVeboFriendsAPI,formData,httpOptions).pipe(map(res=>{
        return res;
    }),
    catchError(err => {
      
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      
      return err.error;
    }));
  }

  get_vebo_friends_by_other(formData,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.getVeboFriendsByOtherAPI,formData,httpOptions).pipe(map(res=>{
        return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
  
  send_friend_request(friend_id,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.sendFriendRequestAPI,{'friend_id':friend_id},httpOptions).pipe(map(res=>{
        return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
  
  remove_friend(friend_id,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
		  'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.removeFriendAPI,{'friend_id':friend_id},httpOptions).pipe(map(res=>{
        return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
  
  confirm_friend_request(friend_id,headers,notification_id){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.confirmFriendRequestAPI,{'friend_id':friend_id,'notification_id':notification_id},httpOptions).pipe(map(res=>{
        return res;
    }),
    catchError(err => {
      if(err.status == 401)
      {
        localStorage.removeItem('authUser')
        localStorage.removeItem('login_token')
        localStorage.removeItem('profile_img_url')
        window.location.href = '/login'
      }
      return err.error;
    }));
  }
  

  get_couples(formData,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          //'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.getCouplesApi,formData,httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }


  getNewsFeed(page,headers){

    const httpOptions = {
        headers: new HttpHeaders({
          //'Vebo-Key': 'sdfsf456s1df21sfs89f7sfsfs23f12s3f4s',
          'Content-Type': 'application/json',
          'Authorization':'Bearer '+headers,
          'requestFrom':this.siteRequestFrom,
        })
      };
      return this.http.post<any>(this.apiDomain+this.getNewsFeedApi,{page : page},httpOptions).pipe(map(res=>{
        return res;
      }),
      catchError(err => {
        if(err.status == 401)
        {
          localStorage.removeItem('authUser')
          localStorage.removeItem('login_token')
          localStorage.removeItem('profile_img_url')
          window.location.href = '/login'
        }
        return err.error;
      }));
  }

  
}




